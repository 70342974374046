<!--
 * @Description: 我的页面配置
-->
<template>
	<div class="wrap">
		<div style="padding-right:360px">
			<phone-ctn>
				<me-bar></me-bar>
			</phone-ctn>
		</div>

		<!-- 导航按钮 -->
		<config-ctn title="个人中心设置">
			<div class="p10">
				<label class=" f12">此配置适用于----- 个人中心</label>

				<config-item label='默认logo'>
					<FaImageUpload :maxCount="1" v-model="project.setting.me.headImg" />
				</config-item>

				<!-- <config-item label='logo边距'>
        <el-input placeholder="请输入数字" size="small"></el-input>
      </config-item>-->

				<config-item label='背景图片高度'>
					<el-input placeholder="如果是小程序模式,高度请大于350px" v-model="project.setting.me.topBgHeight"
						size="small"></el-input>
				</config-item>
				
				<config-item label='背景图片'>
					<FaImageUpload :maxCount="1" v-model="project.setting.me.bgImg" />
				</config-item>

				<config-item label='显示我的订单'>
					<el-switch class="mt8" v-model="project.setting.me.showOrderMenu">
					</el-switch>
				</config-item>


				<config-item label='显示今日预约'>
					<el-switch class="mt8" v-model="project.setting.me.showTodayBooking">
					</el-switch>
				</config-item>


				<config-item label='显示预约菜单'>
					<el-switch class="mt8" v-model="project.setting.me.showBookingMenu">
					</el-switch>
				</config-item>

				<config-item label='显示商城菜单'>
					<el-switch class="mt8" v-model="project.setting.me.showMallMenu">
					</el-switch>
				</config-item>



				<config-item label='显示推荐商品'>
					<el-switch class="mt8" v-model="project.setting.me.showRecommended">
					</el-switch>
				</config-item>

				<config-item label='客服二维码'>                             
					<FaImageUpload :maxCount="1" v-model="project.setting.me.customerServiceQRCodeImg" />
				</config-item>
				
				<config-item label='功能菜单json'>
					<el-input placeholder="菜单json" v-model="project.setting.me.menuJson"
						size="big"></el-input>
				</config-item>
				

			</div>



		</config-ctn>

	</div>
</template>

<script>
	import MeBar from "@/components/MeBar";
	import {
		mapGetters
	} from "vuex";

	export default {
		name: "meTpl",

		components: {
			MeBar,
		},

		computed: {
			...mapGetters(["project"]),
		},
		mounted() {

		},

		methods: {


		},
	};
</script>

<style lang="scss" scoped>
	.nav-item {
		padding: 10px;
		margin-bottom: 10px;
		border-radius: 2px;
		background-color: #fff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	}
</style>